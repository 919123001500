//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage";
import MainTitle from "@/components/MainTitle.vue";
import DataLoading from "@/components/Loading/DataLoading";
import CalendarMixin from "@/mixins/Calendar";
import MyShopsMixin from "@/mixins/MyShops";
import AppSelect from "@/components/AppSelect.vue";
import AppSelectMulti from "@/components/AppSelectMulti.vue";
import AppTabs from "@/components/AppTabs.vue";
import DataTableActions from "../components/DataTable/DataTableActions.vue";
import { getEntityCellsConfig } from "@/utils/enums";
import CompareTable from "@/components/Connections/CompareTable";
import CompareTableBySKU from "@/components/Connections/CompareTableBySKU";
import LargeChart from "@/components/charts/LargeChart.vue";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import HasTokens from "@/components/HasTokens";
export default {
  mixins: [CalendarMixin, MyShopsMixin],
  meta: {
    title: "Сравнение периодов"
  },
  data() {
    const tabs_tables = [{
      id: 1,
      title: "Обзор"
    }, {
      id: 2,
      title: "Динамика"
    }];
    const cells = this.$store.state.connections.marketplace.title === "Wildberries" ? getEntityCellsConfig("shopWb") : getEntityCellsConfig("shopOzon");
    return {
      calendarInternal: null,
      calendarInternal2: null,
      calendar: [],
      calendar2: [],
      cells,
      select_chart_model: cells[Object.keys(cells)[0]],
      tabs_tables,
      tabs_tables_model: tabs_tables[0],
      loaded: null,
      error: false
    };
  },
  created() {
    this.setCalendar();
  },
  methods: {
    async loadOverview() {
      var _dynamic;
      let dynamic = null;
      if (this.marketplace.title === "Wildberries") {
        dynamic = await this.$store.dispatch("connections/getWbCompareByDays", this.reportSettings);
      }
      if (this.marketplace.title === "OZON") {
        dynamic = await this.$store.dispatch("connections/getOzCompareByDays", this.reportSettings);
      }
      let res = {};
      const keys = Object.keys((_dynamic = dynamic) === null || _dynamic === void 0 ? void 0 : _dynamic.data[0]);
      keys.forEach(item => {
        if (item.slice(-1) === "1" || item.slice(-1) === "2") {
          res = {
            ...res,
            [item]: {
              dynamic1: dynamic.data.map(i => {
                return i[`${item.slice(0, -1)}1`];
              }),
              dynamic2: dynamic.data.map(i => {
                return i[`${item.slice(0, -1)}2`];
              })
            }
          };
        }
      });
      return res;
    },
    getLoaded(type, index) {
      if (!this.loaded || !this.loaded[type + index]) {
        return {
          total: null,
          [`dynamic${index}`]: []
        };
      }
      return this.loaded[type + index];
    },
    setCalendar() {
      var _this$$store$state$co;
      const lastAvailableDate = ((_this$$store$state$co = this.$store.state.connections.calendarBoundaries) === null || _this$$store$state$co === void 0 ? void 0 : _this$$store$state$co[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 7);
      const lastWeekRange = dateRangeFromDate(dateRangeFromDate(weekRange[0], 2)[0], 7);
      this.calendar = dateRangeBoundaries([weekRange[0], weekRange[1]], this.$store.state.connections.calendarBoundaries);
      this.calendar2 = dateRangeBoundaries([lastWeekRange[0], lastWeekRange[1]], this.$store.state.connections.calendarBoundaries);
    },
    swapPeriods() {
      const temp = this.calendar;
      this.calendar = this.calendar2;
      this.calendar2 = temp;
    }
  },
  computed: {
    //&:
    fileName() {
      const dates1 = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      const dates2 = this.calendar2.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return `${this.marketplace.title} Сравнение периодов ${dates1} - ${dates2}`;
    },
    tableKey() {
      return `${this.tabs_tables_model.id}`;
    },
    reportSettings() {
      if (!this.calendar || !this.calendar2) {
        return null;
      }
      const dates1 = this.calendar.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      const dates2 = this.calendar2.map(item => {
        const split = item.split("-");
        return split[1] + "-" + split[2] + "-" + split[0];
      });
      return {
        date1_1: dates1[0],
        date1_2: dates1[1],
        date2_1: dates2[0],
        date2_2: dates2[1],
        tokens: this.selectedTokens.map(item => item.id)
      };
    },
    select_chart_items() {
      const result = [];
      Object.keys(this.cells).forEach(key => {
        if (!this.cells[key].multi) {
          result.push(this.cells[key]);
        }
      });
      return result;
    },
    chartDatasets() {
      const cell = this.cells[this.select_chart_model.id];
      return [{
        color: "red",
        data: this.getLoaded(this.select_chart_model.id, "1").dynamic1,
        unit: cell.unit,
        title: cell.title
      }, {
        color: "blue",
        data: this.getLoaded(this.select_chart_model.id, "2").dynamic2,
        unit: cell.unit,
        title: cell.title
      }];
    }
  },
  watch: {
    reportSettings: {
      immediate: true,
      async handler(v, old) {
        if (JSON.stringify(v) === JSON.stringify(old)) {
          return;
        }
        this.error = false;
        try {
          this.loaded = await this.loadOverview();
        } catch (e) {
          this.error = true;
          throw e;
        }
      }
    },
    selectedTokens() {
      this.cells = this.$store.state.connections.marketplace.title === "Wildberries" ? getEntityCellsConfig("shopWb") : getEntityCellsConfig("shopOzon");
    }
  },
  components: {
    MainTitle,
    LargeChart,
    ReportPage,
    AppSelect,
    AppSelectMulti,
    AppTabs,
    DataTableActions,
    CompareTable,
    CompareTableBySKU,
    HasTokens,
    DataLoading
  }
};