//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { tableLoaderCreator } from "@/utils/newModule";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      period1: "",
      period2: ""
    };
  },
  methods: {
    async tableLoader({
      query
    }) {
      let split = this.reportSettings.date1_1.split("-");
      const date1_1 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date1_2.split("-");
      const date1_2 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date2_1.split("-");
      const date2_1 = split[1] + "-" + split[0] + "-" + split[2];
      split = this.reportSettings.date2_2.split("-");
      const date2_2 = split[1] + "-" + split[0] + "-" + split[2];
      this.period1 = date1_1 + " - " + date1_2;
      this.period2 = date2_1 + " - " + date2_2;
      const data = await tableLoaderCreator(this.columns, this.$store.state.connections.marketplace.title, this.$store.dispatch, query, this.reportSettings, "connections/getWbCompare", "connections/getOzCompare", {}, {});
      const fields = ["value1", "value2", "diff"];
      data.items.map(item => {
        fields.forEach(field => {
          if (!Number.isInteger(Number(item[field]))) {
            var _Number;
            item[field] = (_Number = Number(item[field])) === null || _Number === void 0 ? void 0 : _Number.toFixed(2);
          } else {
            item[field] = Number(item[field]);
          }
        });
      });
      return data;
    }
  },
  computed: {
    columns() {
      // const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2)
      return [{
        title: "Показатель",
        show: true,
        name: "name",
        type: "text",
        width: 300,
        filter: "text",
        noOverflow: true
      }, {
        title: `${this.period1}`,
        show: true,
        name: "value1",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: "Разница",
        show: true,
        name: "diff",
        type: "slot",
        width: 200,
        filter: "text",
        noOverflow: true
      }, {
        title: `${this.period2}`,
        show: true,
        name: "value2",
        type: "text",
        width: 200,
        filter: "text",
        noOverflow: true
      }];
    }
  }
};